<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title>Création d'un utilisateur</v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <h4 class="mb-5">
          Informations utilisateur
        </h4>

        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="10"
            md="10"
          >
            <v-text-field
              v-model="lastName"
              :rules="lastNameRules"
              class="mb-5"
              label="Nom"
              outlined
              dense
              placeholder="Nom"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="firstName"
              :rules="firstNameRules"
              label="Prénom"
              class="mb-5"
              outlined
              dense
              placeholder="Prénom"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email"
              class="mb-5"
              outlined
              dense
              placeholder="Email"
              hide-details
              autocomplete="off"
            ></v-text-field>

            <v-select
              v-model="roles"
              :items="availableRoles"
              label="Role"
              class="mt-5"
              outlined
              multiple
              persistent-hint
            ></v-select>

            <v-divider class="v-divider-entity mt-2 mb-5"></v-divider>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Mot de passe"
              placeholder=""
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              class="mb-3"
              autocomplete="off"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              :rules="passwordRules"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Confirmation du mot de passe"
              placeholder=""
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              autocomplete="off"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'userList' }"
            >
              Annuler
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import config from '../../config'
import router from '@/router'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      roles: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      availableRoles: ['ROLE_ADMIN', 'ROLE_USER'],
      isPasswordVisible: false,
      lastName: '',
      lastNameRules: [
        v => !!v || 'Le nom est obligatoire'
      ],
      firstName: '',
      firstNameRules: [
        v => !!v || 'Le prénom est obligatoire'
      ],
      email: '',
      emailRules: [
        v => !!v || 'L\'email prénom est obligatoire',
        v => /.+@.+\..+/.test(v) || 'L\'e-mail doit avoir un format valide'
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Le mot de passe est obligatoire'
      ],
      confirmPassword: '',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  computed: {
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.password !== this.confirmPassword) {
        this.errors.push({ title: 'Les mots de passe ne correspondent pas.' })

        return
      }

      if (this.valid) {
        this.postUser()
      }
    },
    postUser () {
      this.valid = false
      this.loading = true

      const data = {
        lastName: this.lastName,
        firstName: this.firstName,
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
        roles: this.roles
      }

      Vue.prototype.$http
        .post(config.apiUrl + '/api/users', data, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            const user = response.data

            this.errors = []
            this.snackbar = true
            this.successMessage = 'Utilisateur crée avec succès.'

            setTimeout(() => {
              router.push({ name: 'userDetails', params: { userId: user.id } })
            }, 1500)
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    }
  }
}
</script>
